import { Trans } from 'react-i18next'
import { z, t } from '~/lib/i18n'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from './fields/Input'
import Button from '../buttons/Button'
import Radio from './fields/Radio'
import BasicRadio from './fields/BasicRadio'
import NewSelect from './fields/NewSelect'
import {
  panelMountingOptions,
  shortRailPanelMountingOptions,
  corrugatedTinMetalPanelMountingOptions
} from './formOptions'
import Checkbox from './fields/Checkbox'
import React, { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'
import { defaultPanelInfoValues } from '~/slices/ConfiguratorSlice'
import Info from '../Info'
import {
  validateRailDistance,
  DistanceValidation,
  removeFromSessionStorage,
  inferRailDistanceFromPanel,
  resetRailDistance,
  savePanel,
  shouldGenerateNewPanelUid,
  calculatePanelWeight
} from '~/lib/utils'
import { cn } from '~/lib/utils'
import { v4 as uuidv4 } from 'uuid'
import LinkButton from '../buttons/LinkButton'

const validationSchema = z
  .object({
    uid: z.string(),
    name: z.union([z.string(), z.undefined()]),
    system: z.string(),
    width: z.number(),
    height: z.number(),
    weight: z.number(),
    mounting: z.string(),
    useSupportPlates: z.boolean(),
    useThreeRails: z.union([z.string(), z.boolean()]),
    selectedRailDistance: z.number(),
    customRailDistanceIsSet: z.union([z.string(), z.boolean()]),
    doSavePanel: z.boolean()
  })
  .superRefine((schema, ctx) => {
    parallellRailDistanceRefiner(schema, ctx)
  })
  .refine(
    (schema) =>
      (schema.doSavePanel && schema.name !== undefined && schema.name !== '') ||
      !schema.doSavePanel,
    {
      message: t('Ange panelnamn'),
      path: ['name']
    }
  )

type ValidationSchema = z.infer<typeof validationSchema>

const parallellRailDistanceRefiner = (
  schema: ValidationSchema,
  ctx: z.RefinementCtx
) => {
  const distanceValidation = validateRailDistance(
    schema.width,
    schema.height,
    { mounting: schema.mounting, system: schema.system },
    schema.selectedRailDistance
  )
  switch (distanceValidation) {
    case DistanceValidation.TooSmall:
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Önskat avstånd är för litet'),
        path: ['selectedRailDistance']
      })
      break
    case DistanceValidation.TooLarge:
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Önskat avstånd är för stort'),
        path: ['selectedRailDistance']
      })
      break
    default:
      break
  }
}

/**
 * Generates an array of options for parallel settings based on the roof properties.
 *
 * @param {Roof} roof - The roof object containing properties such as covering and attachment.
 * @returns {NameAndLabel[]} An array of objects, each containing a name and label for the options.
 */
const getParallelOptions = (roof: Roof): NameAndLabel[] => {
  const options = []

  /** Roof options */
  const roofSlopeExceedsLimit = roof.slope > 30
  const roofCoveringIsFlat = roof.covering == 'flat'
  const roofAttachmentIsSealingPlate =
    roof?.attachment == 'sealing_plate_flat' ||
    roof?.attachment == 'sealing_plate_perforated'
  if (
    !roofSlopeExceedsLimit &&
    roofCoveringIsFlat &&
    roofAttachmentIsSealingPlate
  ) {
    options.push({
      name: 'useSupportPlates',
      label: t('Använd stödplattor')
    })
  }

  return options
}

/**
 * Validates and merges panel information form data with default values.
 *
 * @param data - The form data to validate and merge.
 * @param defaultPanelInfo - The default panel information to merge with.
 * @returns The validated and merged panel information.
 */
const getValidPanelInfoFormData = (
  data: ValidationSchema,
  defaultPanelInfo: PanelInfo
): ValidationSchema => {
  const validData = validationSchema.parse(data)

  return {
    ...defaultPanelInfo,
    ...validData,
    uid: shouldGenerateNewPanelUid(validData.doSavePanel, validData.uid)
      ? uuidv4()
      : validData.uid,
    useThreeRails: validData.useThreeRails == 'true' ? true : false,
    widthMounted: validData.width,
    heightMounted: validData.height
  } as ValidationSchema
}

/**
 * Determines if there is a conflict between the mounting type and the panel orientation.
 * That is to say if mounting is set to portrait but the panel is in landscape mode and vice versa.
 *
 * @param mounting - The mounting type, which can be '90-portrait', '0-portrait', '90-landscape', or '0-landscape'.
 * @param isPortrait - A boolean indicating if the orientation is portrait.
 * @returns A boolean indicating if there is a conflict between the mounting type and the orientation.
 */
const isMountingAndOrientationConflicting = (
  mounting: string,
  isPortrait: boolean
): boolean => {
  if (
    ((mounting == '90-portrait' || mounting == '0-portrait') && !isPortrait) ||
    ((mounting == '90-landscape' || mounting == '0-landscape') && isPortrait)
  )
    return true

  return false
}

/**
 * Infers the correct mounting orientation based on the provided mounting string and orientation flag.
 *
 * @param {string} mounting - The mounting string in the format "ANGLE-ORIENTATION" e.g 90-portrait.
 * @param {boolean} isPortrait - A flag indicating if panel is in portrait mode or landscape (standing vertically or lying horizontally).
 * @returns {string} - The inferred mounting string with the correct orientation.
 */
const inferMounting = (mounting: string, isPortrait: boolean): string => {
  const mountingAngle = mounting.split('-')[0]
  const orientation = mounting.split('-')[1]

  if (isMountingAndOrientationConflicting(mounting, isPortrait)) {
    switch (orientation) {
      case 'portrait':
        return `${mountingAngle}-landscape`
      default:
        return `${mountingAngle}-portrait`
    }
  }

  return mounting
}

const FormPanelSettings = React.memo(() => {
  const {
    roof,
    activeArea,
    panelAreas,
    panelInfo,
    user,
    setUser,
    setPanelInfo,
    setShowPanelSettings,
    updatePanelArea
  } = useBoundStore(
    (state: StoreState) => ({
      ...state,
      roof: state.computed.currentRoof
    }),
    shallow
  )

  const selectedArea = activeArea !== null
  const defaultFormValues = {
    uid: panelInfo.uid,
    name: panelInfo.name,
    system: 'parallel',
    width: panelInfo.width,
    height: panelInfo.height,
    weight: panelInfo.weight,
    mounting: panelInfo.mounting,
    useSupportPlates: panelInfo.useSupportPlates,
    useThreeRails: panelInfo.useThreeRails.toString(),
    customRailDistanceIsSet: panelInfo.customRailDistanceIsSet,
    selectedRailDistance: panelInfo.selectedRailDistance,
    doSavePanel: false
  }

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: defaultFormValues
  })

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    getValues,
    formState: { isDirty }
  } = form

  const width = watch('width')
  const height = watch('height')
  const mounting = watch('mounting')
  const doSavePanel = watch('doSavePanel')
  const uid = watch('uid')
  const customRailDistanceIsSet = watch('customRailDistanceIsSet')
  const [options, setOptions] = useState<NameAndLabel[]>([] as NameAndLabel[])

  const optionsToShow = options.map((option) => {
    return (
      <Checkbox
        key={option.name}
        name={option.name}
        label={option.label}
        className={cn('mb-6')}
      />
    )
  })

  const panelAreaIndex = panelAreas.findIndex(
    (panelArea) => panelArea.uid === activeArea
  )
  const currentPanel = user?.panels?.find((panel) => panel.uid === uid)
  const currentPanelIsSet = currentPanel !== undefined
  const calculatedRailDistance = inferRailDistanceFromPanel(width, height, {
    mounting: mounting,
    system: 'parallel'
  })
  const isPortrait = height > width
  const activePanelAreaInfo =
    panelAreaIndex != -1 ? panelAreas[panelAreaIndex].panelInfo : null

  useEffect(() => {
    if (activeArea !== null && panelAreaIndex !== -1) {
      const panelInfo = panelAreas[panelAreaIndex].panelInfo

      reset({
        ...panelInfo,
        useThreeRails: panelInfo.useThreeRails.toString(),
        doSavePanel: false
      })
    }
  }, [activeArea])

  useEffect(() => {
    if (roof === undefined) return
    const parallelOptions = getParallelOptions(roof)
    setOptions(parallelOptions)

    /**
     * If no option for choosing support plates is found in the parallel form options,
     * we have to set the useSupportPlates value to false in both local storage and form state.
     * Otherwise the form won't be in the correct state. Also, do not use the external option state here.
     * It will not update fast enough, use the parallelOptions instead.
     * */
    if (
      parallelOptions.find((option) => option.name === 'useSupportPlates') ==
      undefined
    ) {
      setValue('useSupportPlates', false)
      localStorage.setItem('useSupportPlates', 'false')

      /**
       * Update every panel area to not use support plates if the option is not available.
       */
      for (const panelArea of panelAreas) {
        updatePanelArea({
          ...panelArea,
          panelInfo: {
            ...panelArea.panelInfo,
            useSupportPlates: false
          }
        })
      }
    }
  }, [roof?.covering, roof?.attachment, roof?.slope])

  useEffect(() => {
    /**
     * Do this to flip width and height values based on the panel orientation.
     * To get correct dimensions we need to take heed to the active panel area.
     * Otherwise the correct width and height might be overwritten in some cases.
     * */
    if (isMountingAndOrientationConflicting(mounting, isPortrait)) {
      setValue(
        'width',
        activePanelAreaInfo ? activePanelAreaInfo.height : height
      )
      setValue(
        'height',
        activePanelAreaInfo ? activePanelAreaInfo.width : width
      )

      setValue(
        'selectedRailDistance',
        inferRailDistanceFromPanel(width, height, {
          system: 'parallel',
          mounting
        })
      )
      return
    }

    if (!customRailDistanceIsSet && !activeArea) {
      setValue(
        'selectedRailDistance',
        inferRailDistanceFromPanel(width, height, {
          system: 'parallel',
          mounting
        })
      )
    }
  }, [mounting])

  useEffect(() => {
    /** If we have an active area, don't recalculate rail distance */
    if (activeArea) return

    if (isMountingAndOrientationConflicting(mounting, isPortrait)) {
      setValue('width', height)
      setValue('height', width)
    }

    if (!customRailDistanceIsSet) {
      setValue('selectedRailDistance', calculatedRailDistance)
    }
  }, [width, height])

  useEffect(() => {
    const mountingStringArray = mounting.split('-')

    if (
      roof?.covering === 'corrugated_tin_metal' ||
      (roof?.attachment === 'short_rail' && mountingStringArray[0] === '0')
    ) {
      mountingStringArray[0] = '90'
      setValue('mounting', mountingStringArray.join('-'))
    }
  }, [roof?.covering, roof?.attachment])

  const selectPanel = (uid: string) => {
    const selectedPanel = user?.panels?.find((panel) => panel.uid === uid)
    if (selectedPanel == undefined || activeArea) return

    setValue('customRailDistanceIsSet', false)

    const formValues = getValues()

    /** When repopulating the form fields with chosen panel data,
     * we have to check the width and height of panel and
     * the chosen system to see if the values should be flipped */
    const height = isMountingAndOrientationConflicting(
      mounting,
      selectedPanel.width < selectedPanel.height
    )
      ? selectedPanel.width
      : selectedPanel.height
    const width = isMountingAndOrientationConflicting(
      mounting,
      selectedPanel.width < selectedPanel.height
    )
      ? selectedPanel.height
      : selectedPanel.width

    try {
      const validData = getValidPanelInfoFormData(formValues, panelInfo)
      reset({
        ...validData,
        ...selectedPanel,
        // Ensure the form resets with the uid from the active area if it's set, not always using the most recently selected panel.
        uid: activeArea ? validData.uid : selectedPanel.uid,
        selectedRailDistance: inferRailDistanceFromPanel(width, height, {
          system: 'parallel',
          mounting
        }),
        height,
        width,
        useThreeRails: validData.useThreeRails.toString()
      })
    } catch (error) {
      const zodErr = error as z.ZodError
      reset({ ...selectedPanel, ...formValues, height, width })
      zodErr.issues.forEach((issue) => {
        form.setError(issue.path[0] as any, {
          type: 'manual',
          message: issue.message
        })
      })
    }
  }

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    try {
      const panelInfoFormData = getValidPanelInfoFormData(data, panelInfo)

      const validData = {
        ...panelInfo,
        ...panelInfoFormData
      }

      if (activeArea !== null) {
        updatePanelArea({
          ...panelAreas[panelAreaIndex],
          panelInfo: validData as PanelInfo,
          railsPerRow: validData.useThreeRails ? 3 : 2,
          useSupportPlates: validData.useSupportPlates
        })
      } else {
        setPanelInfo(validData as PanelInfo)

        if (doSavePanel) {
          savePanel(validData as PanelInfo, (res) => {
            setUser({ ...user, panels: res.data.panels } as User)

            /**
             * Have to set uid here, because savePanel is async.
             * If we don't, the uid will be empty when the form is reset.
             * This is because no corresponding panel is found in the
             * user.panels after re-render, since the savePanel function
             * hasn't finished yet. Therefore we need to set the uid here,
             * after the savePanel function has finished.
             */
            setValue('uid', validData.uid)
          })
        }

        localStorage.setItem('panelMounting', validData.mounting.toString())
        localStorage.setItem(
          'useSupportPlates',
          validData.useSupportPlates.toString()
        )
        localStorage.setItem(
          'useThreeRails',
          validData.useThreeRails.toString()
        )
        localStorage.setItem('system', validData.system)
        sessionStorage.setItem(
          'selectedRailDistance',
          validData.selectedRailDistance.toString()
        )
      }

      setShowPanelSettings(false)

      reset({
        uid: validData.uid,
        name: validData.name,
        height: validData.height,
        width: validData.width,
        weight: validData.weight,
        mounting: validData.mounting,
        useSupportPlates: validData.useSupportPlates,
        useThreeRails: validData.useThreeRails.toString(),
        customRailDistanceIsSet: validData.customRailDistanceIsSet,
        selectedRailDistance: validData.selectedRailDistance,
        doSavePanel: false
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  const getMountingOptions = () => {
    if (roof?.attachment === 'short_rail') {
      return shortRailPanelMountingOptions
    }
    if (roof?.covering === 'corrugated_tin_metal') {
      return corrugatedTinMetalPanelMountingOptions
    }
    return panelMountingOptions
  }

  return (
    <FormProvider {...form}>
      <form
        className="mb-6 grid w-full grid-cols-2 gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="heading-m col-span-full mb-6">
          {panelAreaIndex > -1
            ? `${t('Redigera inställningar - Panelyta')} ${panelAreaIndex + 1}`
            : t('Panelinställningar')}
        </h2>
        <h3 className="col-span-full text-lg font-bold">{t('Dimensioner')}</h3>

        {/* Show saved panels if user has any */}
        {user && user.panels && user.panels.length > 0 ? (
          <div className={cn('col-span-full mb-4')}>
            <NewSelect
              name="uid"
              label={t('Mina paneler')}
              placeholder={t('Välj panel')}
              className={cn('mb-2')}
              disabled={activeArea != null}
              options={user.panels
                .map((panel) => {
                  return { value: panel.uid, label: panel.name }
                })
                .toSorted((a, b) => a.label.localeCompare(b.label))}
              onChange={selectPanel}
            />

            {currentPanel && (
              <div className={cn('col-span-full flex')}>
                <p className={cn('text-sm font-normal')}>
                  <strong className={cn('font-bold')}>
                    {t('Dimensioner: ')}
                  </strong>
                  {currentPanel.width} x {currentPanel.height} mm,{' '}
                  {currentPanel.weight} kg
                </p>
                <LinkButton
                  onClick={(e) => {
                    e.preventDefault()
                    reset({
                      ...defaultPanelInfoValues,
                      useThreeRails:
                        defaultPanelInfoValues.useThreeRails.toString()
                    })

                    setValue('uid', '')
                  }}
                  disabled={activeArea != null}
                >
                  {t('Återställ')}
                </LinkButton>
              </div>
            )}
          </div>
        ) : null}

        <Input
          name="system"
          type="hidden"
        />

        <>
          <Input
            name="width"
            type={!currentPanelIsSet ? 'number' : 'hidden'}
            label={t('Bredd') || ''}
            onChange={(e) => {
              const selectedWidth = !isNaN(parseInt(e.target.value))
                ? parseInt(e.target.value)
                : 0
              const isPortrait = height > selectedWidth

              setValue(
                'selectedRailDistance',
                inferRailDistanceFromPanel(selectedWidth, height, {
                  system: 'parallel',
                  mounting
                })
              )
              setValue('weight', calculatePanelWeight(selectedWidth, height))
              setValue('customRailDistanceIsSet', false)

              setValue('mounting', inferMounting(mounting, isPortrait))
            }}
            className="col-span-1"
            required
            disabled={selectedArea}
          />
          <Input
            name="height"
            type={!currentPanelIsSet ? 'number' : 'hidden'}
            label={t('Höjd') || ''}
            onChange={(e) => {
              const selectedHeight = !isNaN(parseInt(e.target.value))
                ? parseInt(e.target.value)
                : 0
              const isPortrait = selectedHeight > width

              setValue(
                'selectedRailDistance',
                inferRailDistanceFromPanel(width, selectedHeight, {
                  system: 'parallel',
                  mounting
                })
              )
              setValue('weight', calculatePanelWeight(width, selectedHeight))
              setValue('customRailDistanceIsSet', false)

              setValue('mounting', inferMounting(mounting, isPortrait))
            }}
            className="col-span-1"
            required
            disabled={selectedArea}
          />
          <Input
            name="weight"
            type={!currentPanelIsSet ? 'number' : 'hidden'}
            label={t('Vikt') || ''}
            className="col-span-1 mb-4"
            required
            disabled={selectedArea}
          />

          {/* Save panel section */}
          <Checkbox
            name="doSavePanel"
            label={t('Spara panel till min profil')}
            className={cn([
              doSavePanel ? 'mb-2' : 'mb-6',
              currentPanelIsSet ? 'hidden' : ''
            ])}
            disabled={selectedArea || currentPanelIsSet}
          />
        </>

        <Input
          name="name"
          type={doSavePanel ? 'text' : 'hidden'}
          label={t('Panelens namn')}
          className="col-span-full"
          disabled={selectedArea || currentPanelIsSet}
        />

        {/** Mounting section */}
        <h3 className="col-span-full text-lg font-bold">
          {t('Panelmontering')}
        </h3>
        <Radio
          name="mounting"
          options={getMountingOptions()}
          vertical
          rules={{ onChange: () => setValue('customRailDistanceIsSet', false) }}
          disabled={selectedArea}
        />

        {mounting === '0-landscape' &&
        roof?.covering === 'corrugated_tin_metal' ? (
          <Trans i18nKey="system_page_info">
            <p className="col-span-full mb-4">
              <span className="font-bold">Information:</span> Tänk på att det är
              svårt att träffa klämzoner vid detta montage.
            </p>
          </Trans>
        ) : null}

        {mounting === '90-landscape' || mounting === '0-portrait' ? (
          <Trans i18nKey="system_page_info">
            <p className="col-span-full mb-4">
              <span className="font-bold">Information:</span> Säkerställ med din
              paneltillverkare att det är ett godkänt montage.
            </p>
          </Trans>
        ) : null}

        <BasicRadio
          name="useThreeRails"
          className="mb-2 flex-row gap-7"
          label={t('Antal skenor')}
          options={[
            {
              value: false,
              label: t('2 skenor') || ''
            },
            {
              value: true,
              label: t('3 skenor') || ''
            }
          ]}
        />

        {/* Rail distance section */}
        <div className={cn('col-span-full mb-8 grid grid-cols-2 gap-4')}>
          <div className={cn('flex flex-col')}>
            <Input
              name="selectedRailDistance"
              type="number"
              icon={
                <Info
                  iconSize="lg"
                  id="selectedRailDistance-icon"
                  text={t('Måttet som anges avser CC-mått.')}
                />
              }
              label={t('Avstånd mellan klämzoner') || ''}
              onChange={() => setValue('customRailDistanceIsSet', true)}
              columnPosition={{ start: 1, span: 1 }}
              required
              unit={t('mm')}
            />
            {customRailDistanceIsSet ? (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  resetRailDistance(
                    width,
                    height,
                    { mounting, system: 'parallel' },
                    (railDistance) => {
                      setValue('selectedRailDistance', railDistance)
                      setValue('customRailDistanceIsSet', false)
                      removeFromSessionStorage('selectedRailDistance')
                      clearErrors('selectedRailDistance')
                    }
                  )
                }}
                className={cn(
                  'col-start-1 ml-auto text-sm font-light underline'
                )}
              >
                {t('Återställ')}
              </button>
            ) : null}
          </div>
        </div>

        {options.length > 0 ? (
          <>
            <h3 className="col-span-full text-lg font-bold">
              {t('Tillval')}{' '}
              <span className="font-normal">{t('(valfritt)')}</span>
            </h3>
            {optionsToShow}
          </>
        ) : null}
        <div className="col-start-2 flex justify-end">
          <Button>{isDirty ? t('Uppdatera') : t('Klar')}</Button>
        </div>
      </form>
    </FormProvider>
  )
})

export default FormPanelSettings!
